@media (max-width: 992px) {
    .users-table{

        .btn-group{
            display: flex;
            flex-direction: column;

            a, button{
                border-radius: 8px !important;
                font-size: 12px;
            }
        }

        tbody{
            td{
                font-size: 12px;
            }
        }
    }
}
