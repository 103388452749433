.calculator-body{
    .calculator-content{
        width: 100%;
    }

    .calculator-currencies{
        width: 100%;
    }

    .currency-table-item{
        margin-bottom: 40px;

        h4{
            font-weight: 900;
        }
    }

    .currencies-table {
        width: 100%;

        table{
            width: 100%;
        }
    }
}


