// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';
@import 'rules';
@import 'calculator';

// Bootstrap
@import 'bootstrap/scss/bootstrap';
